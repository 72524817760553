import createClient from "@tt/vue-components/src/services/apiClient";
import apiServiceHelpers from "@tt/vue-components/src/services/apiServiceHelpers";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService";
const apiClient = createClient(EnvProvider.value("INVENTORY_API_URL"), EnvProvider.value("INVENTORY_API_VERSION"));

const InventoryService = {
  settings: {
    all: () => {
      return apiClient.get("settings:all")
    },
  },
  blacklist: {
    ...baseApiService(apiClient, 'blacklist_skus')
  },
  locations: {
    ...baseApiService(apiClient, 'locations'),
  },
  locationTree: {
    get: (config) => {
      return apiClient.get('location_tree' + apiServiceHelpers.buildQueryParams(config), {
        headers: {
          "Accept": "application/ld+json"
        }
      })
    },
  },
  reservationGroups: {
    ...baseApiService(apiClient, 'reservation_groups')
  },
  reservationItems: {
    ...baseApiService(apiClient, 'reservation_items')
  },
  ruleLinks: {
    ...baseApiService(apiClient, 'rule_links')
  },
  reservations: {
    ...baseApiService(apiClient, 'reservations')
  },
  rules: {
    ...baseApiService(apiClient, 'rules')
  },
  skus: {
    ...baseApiService(apiClient, 'skus'),
    calculate: (data) => {
      return apiClient.put("skus/calculate", data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
    },
    backorders: (data) => {
      return apiClient.put("skus/backorders", data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
    },
  },
  stockOnOrder: {
    ...baseApiService(apiClient, 'stock_on_orders'),
    recalculateStock: (task) => {
      return apiClient.post("stock-on-orders:recalculate_stock_on_order_stock", task)
    },
  },
  virtualStocks: {
    ...baseApiService(apiClient, 'virtual_stocks'),
    absolute: (config) => {
      return apiClient.get("stock/absolute" + apiServiceHelpers.buildQueryParams(config))
    },
    delta: (config) => {
      return apiClient.get("stock/delta" + apiServiceHelpers.buildQueryParams(config))
    },
    live: (config) => {
      return apiClient.get("stock/live" + apiServiceHelpers.buildQueryParams(config))
    },
    syncVirtualStock: (task) => {
      return apiClient.post("virtual_stocks:sync_virtual_stock", task)
    },
  },
  adapters: {
    ...baseApiService(apiClient, 'adapters'),
  },
  targets: {
    ...baseApiService(apiClient, 'targets'),
  },
  stockAllocationItems: {
    ...baseApiService(apiClient, 'stock_allocation_items')
  },
  stockMutations: {
    ...baseApiService(apiClient, 'stock_mutations'),
    statistics: (config) =>{
      return apiClient.get("stock_mutations/statistics" + apiServiceHelpers.buildQueryParams(config))
    }
  },
  stock: {
    ...baseApiService(apiClient, 'stocks')
  },
  virtualIncludedLocations: {
    ...baseApiService(apiClient, 'virtual_included_locations')
  },
  virtualLocations: {
    ...baseApiService(apiClient, 'virtual_locations')
  },
  virtualStockSyncs: {
    ...baseApiService(apiClient, 'virtual_stock_syncs')
  },
  virtuals: {
    ...baseApiService(apiClient, 'virtuals'),
    recalculateStock: (task) => {
      return apiClient.post("virtuals:recalculate_virtual_stock", task)
    },
  },
  taskStatus: {
    ...baseApiService(apiClient, 'task_statuses')
  },
  tasks: {
    ...baseApiService(apiClient, 'tasks')
  },
  generic: {
    delete: (iri) => {
      return apiClient.delete(iri)
    },
    update: (iri, data) => {
      return apiClient.put(iri, data)
    },
    create: (iri, data) => {
      return apiClient.post(iri, data)
    },
  },
  apiClient: {
    apiClient
  }
}
export default InventoryService;
