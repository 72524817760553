import InventoryService from "@/services/InventoryService";

const locations = {
  namespaced: true,
  state: {
    locations: [],
    loading: false
  },
  mutations: {
    SET_LOCATIONS(state, locations) {
      state.locations = locations
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    load({ commit }) {
      commit("SET_LOADING", true)
      const params = {
        search: [
          {column: 'pagination', value: false},
          {column: 'section', value: false}
        ],
        sorting: { sortBy: ["name"], sortDesc: [false] }
      }
      InventoryService.locations.list(params)
        .then(response => {
          if (response) {
            commit("SET_LOCATIONS", response?.items ?? [])
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        });
    },
  },

  getters: {
    activeLocations: state => {
      return state.locations.filter((location)=> {location.active === true})
    }
  }
};

export default locations;
