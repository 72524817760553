import Vue from "vue";
import Vuex from "vuex";
import settings from "@/store/modules/settings"
import inventoryLocationsOverview from "@/store/modules/inventoryLocationsOverview";
import skusOverview from "@/store/modules/skusOverview";
import stockMutationsOverview from "@/store/modules/stockMutationsOverview";
import synchronisationLogOverview from "@/store/modules/synchronisationLogOverview";
import reservationsOverview from "@/store/modules/reservationsOverview";
import virtualsOverview from "@/store/modules/virtualsOverview";
import rulesOverview from "@/store/modules/rulesOverview";
import virtualLocations from "@/store/modules/virtuals/virtualLocations";
import virtualIncludedLocations from "@/store/modules/virtuals/virtualIncludedLocations";
import adaptersOverview from "@/store/modules/adaptersOverview";
import expectedStockOverview from "@/store/modules/expectedStockOverview";
import locations from "@/store/modules/locations";

Vue.use(Vuex);
const debug = false; //process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    settings,
    skusOverview,
    stockMutationsOverview,
    synchronisationLogOverview,
    reservationsOverview,
    inventoryLocationsOverview,
    virtualsOverview,
    rulesOverview,
    virtualLocationsOverview: virtualLocations,
    virtualIncludedLocationsOverview: virtualIncludedLocations,
    adaptersOverview,
    expectedStockOverview,
    locations,
  },
  strict: debug,
  plugins: debug ? [Vuex.createLogger()] : []
});

export default store;
