import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";
import skuFilter from "@/store/modules/expectedStock/filters/skuFilter";
import nameFilter from "@/store/modules/expectedStock/filters/nameFilter";
import isDeliveredFilter from "@/store/modules/expectedStock/filters/isDeliveredFilter";
import isSaleableFilter from "@/store/modules/expectedStock/filters/isSaleableFilter";

const expectedStockOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    skuFilter,
    nameFilter,
    isSaleableFilter,
    isDeliveredFilter,
  },
  state: {
    ...tableState,
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchItems({ commit, state }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        InventoryService.stockOnOrder.list(params)
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    add({ commit }, resource) {
      return new Promise((resolve, reject) => {
        InventoryService.stockOnOrder.create(resource)
          .then(() => {
            commit("ADD_ITEM", resource);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    delete({ commit }, resource) {
      return new Promise((resolve, reject) => {
        InventoryService.stockOnOrder.delete(resource.id)
          .then(() => {
            commit("DELETE_ITEM", resource);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    patch({ commit }, resource) {
      return new Promise((resolve, reject) => {
        InventoryService.stockOnOrder.patch(resource.id, resource.body)
          .then((result) => {
            commit("EDIT_ITEM", result)
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
  },
  getters: {
    ...tableGetters
  }
};

export default expectedStockOverview;
